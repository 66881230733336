@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #333;
  background: #344e41;
}

body.modal-open {
  overflow: hidden;
}

main{
  min-height: calc(100vh - 195px);
}

main.splash{
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-main{
  background: #344e41;
}

.header{
  width: 100%;
  height: 70px;
  background: #344e41;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

.header .title {
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #FFF;
}

.header .nav-link {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .5px;
  color: #FFF;
}

.footer{
  width: 100%;
  padding: 30px 10px;
  background: #344e41;
  font-size: 14px;
  letter-spacing: .75px;
  text-align: center;
}

.footer div:nth-child(1){
  color: #FFF;
}

.footer div:nth-child(1) span{
  font-weight: 600;
}

.footer div:nth-child(2){
  color: #CCC;
}

@media only screen and (max-width: 768px) {
  .modal{
    margin-top: 15px;
  }

    /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }
}

.modal{
  height: calc(100vh - 90px);
  margin-top: 20px;
}

.modal .modal-bg{
  --tw-shadow: 0px -20px 10px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0px -20px 10px var(--tw-shadow-color);
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
  background-color: #9DBEAE;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}