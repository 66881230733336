.puzzle{
    width: 70vw;
    max-width: 290px;
    z-index: 3;
    padding-bottom: 30px;
    padding-top: 20px;
}

.play-btn{
    margin-top: 25px;
    border-radius: 50px;
    padding: 15px 30px;
    background-color: #f5f5f5 !important;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid #e1e1e1;
    font-size: 14px;
    color: #555;
    cursor: pointer;
    transition: .25s;
}
.play-btn:hover{
    background-color: white;
    color: #222;
}

.word-box {
    margin: auto;
    width: 100%;
    padding: 15px;
    text-align: center;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: 1px;
    height: 65px;
    border-bottom: 3px solid black;
}

.success-box {
    margin: auto;
    width: 100%;
    padding: 15px;
    text-align: center;
    background-color: #344e41;
    color: white;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 1px;
    border-radius: 7px;
    margin-bottom: 30px;
}

.result-box {
    margin: auto;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    position: relative;
}

.progress {
    position: absolute;
    top: 34px;
    left: 12px;
    width: calc(100% - 24px);
    border-radius: 7px; 
    height: 5px;
}

.progress::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 7px;
}

.progress::-webkit-progress-value {
    background-color: #344e41;
    border-radius: 7px;
}

.progress::-moz-progress-bar {
    background-color: transparent;
    border-radius: 7px;
}

.progress::-moz-progress-value {
    background-color: #344e41;
    border-radius: 7px;
}

.result-box .title {
    color: #333;
    font-weight: 500;
    font-size: 14px;
}

.result-box .found-words {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
    color: black;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
}

.error-box {
    opacity: 0;
    width: 100%;
    margin: 15px 0px;
    text-align: center;
}

.error-box.show {
    animation: opac 2s reverse;
}

@keyframes opac {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.error-box span {
    font-size: 12px;
    background-color: #111;
    padding: 6px 10px;
    color: #FFF;
    border-radius: 3px;
}

.letter-box {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
}

.letter-box .hex {
    position: relative;
    width: 100%;
    padding-bottom: 103.9230484542%;
}

.letter-box .hex-cell {
    position: absolute;
    top: 33.3333333333%;
    left: 30%;
    width: 40%;
    height: 33.3333333333%;
}

.letter-box .hex-cell:nth-child(1) {
    transform: translate(0, 0);
}

.letter-box .hex-cell:nth-child(2) {
    transform: translate(-75%, -50%);
}

.letter-box .hex-cell:nth-child(3) {
    transform: translate(0, -100%);
}

.letter-box .hex-cell:nth-child(4) {
    transform: translate(75%, -50%);
}

.letter-box .hex-cell:nth-child(5) {
    transform: translate(75%, 50%);
}

.letter-box .hex-cell:nth-child(6) {
    transform: translate(0, 100%);
}

.letter-box .hex-cell:nth-child(7) {
    transform: translate(-75%, 50%);
}

.hex .cell-letter {
    font-weight: 700;
    font-size: 1.875em;
    text-anchor: middle;
    text-transform: uppercase;
    pointer-events: none;
}

.letter-box .hex-cell:first-child .cell-fill {
    cursor: pointer;
    fill: #344e41;
    transition: all 100ms;
}

.letter-box .hex-cell.center .cell-letter {
    fill: white;
}

.letter-box .hex-cell .cell-fill {
    cursor: pointer;
    fill: #e6e6e6;
    transition: all 100ms;
}

.action-btns {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px auto;
}

.action-btns button {
    border-radius: 50px;
    padding: 7px 25px;
    background-color: white;
    font-weight: 500;
    border: 1px solid #e1e1e1;
    font-size: 14px;
    color: #555;
}

.action-btns .btn-reset {
    margin: 10px 15px;
    height: 20px;
    width: 20px;
}
